import * as d3 from 'd3'
import * as topojson from 'topojson'
import * as flubber from 'flubber'
import * as d3Annotation from 'd3-svg-annotation';

import Chart from '../Chart'
import './USElectionChart.css'

import us from './us.json'
import counties from './countiesData.json'
import statesHex from './states.json'

export default class USElectionChart extends Chart {

  create() {
    this.svg = super.createRoot();
    this.svg.attr("shape-rendering", "geometricPrecision")
    this.main = this.svg.append('g')
      .attr('class', 'main');

    this.loosingCounties = this.main.append('g')
      .attr('class', 'loosingCounties')
      .style('opacity', 0);
      
    this.counties = this.main.append('g')
      .attr('class', 'counties');

    this.nationBoundaries = this.main.append('g')
      .attr('class', 'nationBoundaries')

    this.stateBoundaries = this.main.append('g')
      .attr('class', 'stateBoundary')

    this.statesHex = this.main.append('g')
      .attr('class', 'statesHex')

    this.hexGrid = this.main.append('g')
      .attr('class', 'hexGrid')

    this.tryTo = this.main.append('g')
      .attr('class', 'tryTo')
      .append('text')
      .attr('x', this.props.width/2)
      .attr('y', this.props.height/2)
      .attr('text-anchor', 'middle')
      .style('font-size', this.props.width * 0.07)
      .style('font-weight', 700)
      .attr('fill', 'white')
      .text('Try to impeach this.')
    
    this.legend = this.main.append('g')
      .attr('class', 'legend')

    this.annotations = this.main.append('g')
      .attr('class', 'annotation-group')
      .style('opacity', 0)

    this.bubbleSizeLegend = this.legend.append('g')
      .attr('class', 'bubbleSizeLegend')
      .style('opacity', 0)
    this.bubblePartyLegend = this.legend.append('g')
      .attr('class', 'bubblePartyLegend')
      .style('opacity', 0)
    this.bubbleWinLostLegend = this.legend.append('g')
      .attr('class', 'bubbleWinLostLegend')
      .style('opacity', 0)
  }

  update(state) {
    this.drawChart(state);
  }

  drawChart(state) {
    console.log('stepIndex', state.stepIndex, 'previousStepIndex', state.previousStepIndex)

    const projection = d3.geoAlbersUsa()
      .fitSize([this.props.width, this.props.height], topojson.feature(us, us.objects.counties))

    const path = d3.geoPath(projection);

    const countiesData = counties.map(c => ({
      ...c,
      properties: {
        ...c.properties,
        centerX: c.properties.centerX * this.props.width,
        centerY: c.properties.centerY * this.props.width,
        radius: c.properties.radius * this.props.width,
        leftClipWidth: c.properties.winningSideWidth * this.props.width,
        rightClipWidth: c.properties.loosingSideWidth * this.props.width,
      }
    }))

    /*********** Countries ***********/
    this.countiesData = this.counties
      .selectAll(".countyShape")
      .data(countiesData)

    // left
    this.countiesClipEnter = this.countiesData
      .enter().append("clipPath")
        .attr("class", d => "leftClip")
        .attr("id", d => `leftClip_${d.id}`)
        .append("rect") 
        .attr("class", d => "leftRectangleClip")
        .attr("x", d => d.properties.centerX - d.properties.radius)
        .attr("y", d => d.properties.centerY - d.properties.radius)
        .attr("height", d => d.properties.radius * 2)
        .attr("width", d => d.properties.radius * 2) //d.properties.leftClipWidth)

    this.countiesClipMerge = this.countiesClipEnter
      .merge(this.countiesData)  

    // right
    this.loosingCountiesData = this.loosingCounties
      .selectAll(".loosingCounty")
      .data(countiesData)
    
    this.loosingCountiesClipEnter = this.loosingCountiesData
      .enter().append("clipPath")
        .attr("class", "rightClip")
        .attr("id", d => `rightClip_${d.id}`)
        .append("rect") 
        .attr("class", "rightRectangleClip")
        .attr("x", d => d.properties.centerX + d.properties.radius - d.properties.rightClipWidth)
        .attr("y", d => d.properties.centerY - d.properties.radius)
        .attr("height", d => d.properties.radius * 2)
        .attr("width", d => d.properties.radius * 2) 

    this.loosingCountiesEnter = this.loosingCountiesData
      .enter().append("circle")
        .attr("class", "looserSideCounty")
        .attr("clip-path", d => `url(#rightClip_${d.id})`)
        .attr("fill", county => county.properties.vote.percent.dem <= county.properties.vote.percent.gop ? "#0e0eb9" : "#ea0004") 
        .attr("cx", d => d.properties.centerX )
        .attr("cy", d => d.properties.centerY )
        .attr("r", d => d.properties.radius)
        .attr("stroke", "white")
        .attr("stroke-width", 0.3)

    this.loosingCountiesMerge = this.loosingCountiesEnter
      .merge(this.loosingCountiesData)  
  
    // shapes
    this.countiesEnter = this.countiesData
      .enter().append("path")
        .attr("class", "countyShape")
        //.attr("clip-path", d => `url(#leftClip_${d.id})`)
        .attr("fill", county => county.properties.vote.percent.dem > county.properties.vote.percent.gop ? "#0e0eb9" : "#ea0004")  
        .attr("d", path)
        .attr("stroke", "white")
        .attr("stroke-width", 0.3)
      /*.append("title")
        .text(d => [
          d.properties.state,
      '|',
          d.properties.name,
          `${Math.round(d.properties.vote.percent.dem * 1000)/100}% Clinton`,
          `${Math.round(d.properties.vote.percent.gop * 1000)/100}% Trump`,
          ].join(" – ")
        )*/
    this.countiesMerge = this.countiesEnter
      .merge(this.countiesData)  

    this.countiesMergeTransition = this.countiesMerge
    
    /*********** Nation boundaries ***********/
    const nationBoundaries = topojson.feature(us, us.objects.nation).features
    this.nationBoundariesData = this.nationBoundaries
      .selectAll(".nationBoundary")
      .data(nationBoundaries)
    
    this.nationBoundariesEnter = this.nationBoundariesData
      .enter().append("path")
        .attr("class", "nationBoundary")
        .attr("fill", "none")  
        .attr("d", path)
        .attr("stroke", "black")
        .attr("stroke-width", 0.5)
        .style("opacity", 0)

    this.nationBoundariesMerge = this.nationBoundariesData
      .merge(this.nationBoundariesEnter)

    /*********** State boundaries ***********/
    const stateBoundaries = topojson.feature(us, us.objects.states).features
    this.stateBoundariesData = this.stateBoundaries
      .selectAll(".stateBoundary")
      .data(stateBoundaries)
    
    this.stateBoundariesEnter = this.stateBoundariesData
      .enter().append("path")
        .attr("class", "stateBoundary")
        .attr("fill", "none")  
        .attr("d", path)
        .attr("stroke", "black")
        .attr("stroke-width", 0.5)
        .style("opacity", 0)

    this.stateBoundariesMerge = this.stateBoundariesEnter
        .merge(this.stateBoundariesData)  

    this.stateBoundariesMergeTransition = this.stateBoundariesMerge

    /*********** States hex ***********/

    this.line = d3.line()
      .x(d => (d[0] * (this.props.width*0.75)) + (this.props.width*0.125))
      .y(d => (d[1] * (this.props.width*0.75)) )

    this.statesHexData = this.statesHex
      .selectAll(".stateHex")
      .data(statesHex.map(stateHex => ({
        ...stateHex,
        properties: {
          ...stateHex.properties,
          hexPolygon: this.line(stateHex.properties.hexNormalized)
        }
      })))
    
    this.statesHexEnter = this.statesHexData
      .enter().append("path")
        .attr("class", "stateHex")
        .attr("fill", d => d.properties.color)
        .attr("stroke", "white")
        .attr("stroke-width", 2)
        .attr("stroke-linejoin", "round")
        .attr("d", path)
        .style("opacity", 0)

    this.statesHexMerge = this.statesHexEnter
        .merge(this.statesHexData)  

    this.statesHexMergeTransition = this.statesHexMerge

    /************* Legend *****************/ 
    const radiusScale = d3.scaleSqrt()
      .domain([0, d3.max(countiesData, c => c.properties.vote.count.total)])
      .range([0, d3.max(countiesData, c => c.properties.radius)])
    const population = [200000, 1000000, 2500000];
    const bubbleLegendRadius = radiusScale(population[population.length -1])

    if (this.bubbleSizeLegend.select('.bubbleSizeLegendText').empty()) {
      this.bubbleSizeLegendData = this.bubbleSizeLegend.selectAll('bubbleSize')
        .data(population)

      this.bubbleSizeLegend
        .append('text')
        .attr('class', 'bubbleSizeLegendText')
        .attr('text-anchor', 'start')
        .attr('font-size', this.props.width * 0.013)
        .attr('font-weight', 700)
        .style('fill', 'grey')
        .attr('x', (-radiusScale(population[population.length - 1])/2) - 10)
        .attr('y', d => (-2*radiusScale(population[population.length - 1])) - 15)
        .text('Voter Count')

      this.bubbleSizeLegendData
        .enter().append("circle")
        .attr("class", "bubbleSize")
        .attr("fill", "none")  
        .attr("stroke", "grey")
        .attr("stroke-width", 0.8)
        .attr('r', d => radiusScale(d))
        .attr('cy', d => -radiusScale(d))

      this.bubbleSizeLegendData
        .enter().append("text")
        .attr("class", "bubbleSizeText")
        .attr('text-anchor', 'end')
        .attr('font-size', this.props.width * 0.01)
        .style('fill', 'grey')
        .attr('x', radiusScale(population[population.length - 1]) + this.props.width * 0.055)
        .attr('y', d => (-2*radiusScale(d)) + 8)
        .text(d => d3.format(',')(d))

      this.bubbleSizeLegend
        .attr('transform', `translate(${this.props.width * 0.95}, ${this.props.height * 0.6})`)
    }

    if (this.bubblePartyLegend.select('bubblePartyLegendRep').empty()) {
      this.bubblePartyLegend
        .append("clipPath")
        .attr("class", "repClip")
        .attr("id", "repClip")
        .append("rect") 
        .attr("class", "repRectangleClip")
        .attr("x", -bubbleLegendRadius)
        .attr("y", -bubbleLegendRadius)
        .attr("height", bubbleLegendRadius)
        .attr("width", bubbleLegendRadius * 2)

      this.bubblePartyLegend
        .append("circle")
        .attr("class", "bubblePartyLegendRep")
        .attr("fill", "#e90103")  
        .attr("stroke", "none")
        .attr('r', bubbleLegendRadius)
        .attr("clip-path", "url(#repClip)")

      this.bubblePartyLegend
        .append("clipPath")
        .attr("class", "demClip")
        .attr("id", "demClip")
        .append("rect") 
        .attr("class", "demRectangleClip")
        .attr("x", -bubbleLegendRadius)
        .attr("y", 0)
        .attr("height", bubbleLegendRadius)
        .attr("width", bubbleLegendRadius * 2)

      this.bubblePartyLegend
        .append("circle")
        .attr("class", "bubblePartyLegendDem")
        .attr("fill", "#0e0eb9")  
        .attr("stroke", "none")
        .attr('r', bubbleLegendRadius)
        .attr("clip-path", "url(#demClip)")

      this.bubblePartyLegend
        .append("text")
        .attr("class", "bubblePartyLegendTextRep")
        .attr('text-anchor', 'start')
        .attr('font-size', this.props.width * 0.01)
        .style('fill', 'grey')
        .attr('x', bubbleLegendRadius + 10)
        .attr('y', -bubbleLegendRadius * 0.3)
        .text('Republicans')

      this.bubblePartyLegend
        .append("text")
        .attr("class", "bubblePartyLegendTextDem")
        .attr('text-anchor', 'start')
        .attr('font-size', this.props.width * 0.01)
        .style('fill', 'grey')
        .attr('x', bubbleLegendRadius + 10)
        .attr('y', +bubbleLegendRadius * 0.7)
        .text('Democrats')

      this.bubblePartyLegend
        .attr('transform', `translate(${this.props.width * 0.95}, ${this.props.height * 0.65})`)
    }

    if (this.bubbleWinLostLegend.select('bubbleWinLostLegend').empty()) {
      this.bubbleWinLostLegend
        .append("clipPath")
        .attr("class", "WinClip")
        .attr("id", "WinClip")
        .append("rect") 
        .attr("class", "WinRectangleClip")
        .attr("x", -5)
        .attr("y", -bubbleLegendRadius)
        .attr("height", 10 + bubbleLegendRadius * 2)
        .attr("width", 2*bubbleLegendRadius )

      this.bubbleWinLostLegend
        .append("circle")
        .attr("class", "bubbleWinLostLegendWin")
        .attr("fill", "white")  
        .attr("stroke", "grey")
        .attr('r', bubbleLegendRadius)
        .attr("clip-path", "url(#WinClip)")

      this.bubbleWinLostLegend
        .append("clipPath")
        .attr("class", "LostClip")
        .attr("id", "LostClip")
        .append("rect") 
        .attr("class", "LostRectangleClip")
        .attr("x", -bubbleLegendRadius - 5)
        .attr("y", -bubbleLegendRadius - 5)
        .attr("height", 10 + bubbleLegendRadius * 2)
        .attr("width", 5 + bubbleLegendRadius * 1.2)

      this.bubbleWinLostLegend
        .append("circle")
        .attr("class", "bubbleWinLostLegendLost")
        .attr("fill", "lightgrey")  
        .attr("stroke", "grey")
        .attr('r', bubbleLegendRadius)
        .attr("clip-path", "url(#LostClip)")

      this.bubbleWinLostLegend
        .append("text")
        .attr("class", "bubbleWinLostLegendTextWin")
        .attr('text-anchor', 'end')
        .attr('font-size', this.props.width * 0.01)
        .style('fill', 'grey')
        .attr('x', -3)
        .attr('y', bubbleLegendRadius * 1.5)
        .text('Winning party ←')

      this.bubbleWinLostLegend
        .append("text")
        .attr("class", "bubbleWinLostLegendTextLost")
        .attr('text-anchor', 'start')
        .attr('font-size', this.props.width * 0.01)
        .style('fill', 'grey')
        .attr('x', 10)
        .attr('y', bubbleLegendRadius * 1.5)
        .text('→ Losing party')

      this.bubbleWinLostLegend
        .attr('transform', `translate(${this.props.width * 0.95}, ${this.props.height * 0.725})`)
    }

    if (state.stepIndex === 0 && state.previousStepIndex > 0) {
      this.tryTo
        .transition()
        .delay(2000)
        .style('opacity', 1)
      this.countiesMergeTransition = this.countiesMergeTransition      
        .transition()
        .delay(d => (countiesData.length - d.properties.rank)/2)
        .duration(2000)
        .attrTween('d', d => 
          flubber.fromCircle(d.properties.centerX, d.properties.centerY, d.properties.radius, path(d), d.properties.radius <= 4 ? undefined : {maxSegmentLength: 2}))
    }

    if (state.stepIndex === 1) {
      this.tryTo
        .transition()
        .style('opacity', 0)
      this.countiesMergeTransition = this.countiesMergeTransition      
        .transition()
        .delay(d => d.properties.rank/2)
        .duration(2000)
        .attrTween('d', d => 
          flubber.toCircle(path(d), d.properties.centerX, d.properties.centerY, d.properties.radius, d.properties.radius <= 4 ? undefined : {maxSegmentLength: 2}))
    } 

    if (state.stepIndex >= 1 && state.stepIndex < 3) {
      this.bubbleSizeLegend      
        .transition()
        .delay(2000)
        .style('opacity', 1)
      this.bubblePartyLegend      
        .transition()
        .delay(2000)
        .style('opacity', 1)
    } else {
      this.bubbleSizeLegend
        .style('opacity', 0)
      this.bubblePartyLegend
        .style('opacity', 0)
    }

    if(state.stepIndex > 1 && state.stepIndex < 3) {
      this.bubbleWinLostLegend      
        .transition()
        .style('opacity', 1)
    } else {
      this.bubbleWinLostLegend
        .style('opacity', 0)
    }

    if (state.stepIndex > 1) {
      this.countiesMergeTransition = this.countiesMergeTransition
        .transition()
        .duration(0)
        .attr("clip-path", d => `url(#leftClip_${d.id})`)
      this.counties
        .selectAll(".leftRectangleClip")      
        .transition()
        //.delay(d => d.properties.rank/2)
        .duration(2000)
        .attr('width', d => d.properties.leftClipWidth)
    } else {
      this.countiesMergeTransition = this.countiesMergeTransition
        .transition()
        .duration(0)
        .attr("clip-path", '')
      this.counties
        .selectAll(".leftRectangleClip")
        .attr('width', d => d.properties.radius*2)
    }

    if (state.stepIndex === 2) {
      this.loosingCounties
        .style('opacity', 1)
    } else {
      this.loosingCounties
        .transition()
        .duration(1000)
        .style('opacity', 0)
    }

    if (state.stepIndex > 2) {
      this.tryTo
        .transition()
        .style('opacity', 0)
      this.nationBoundariesMerge      
        .transition()
        .duration(1000)
        .style('opacity', 1)
      if(state.stepIndex === 3) {
        this.stateBoundariesMergeTransition = this.stateBoundariesMergeTransition        
          .transition()
          .delay((d, i)=> 1000 + (i * 50))
          .duration(700)
          .style('opacity', 1)
      }
      this.countiesMergeTransition = this.countiesMergeTransition      
        .transition()
        .delay(state.previousStepIndex === 2 ? Math.random() * 2000 : null)
        .duration(50)
        .style('opacity', 0)
    } else {
      this.nationBoundariesMerge
        .transition()
        .duration(1000)
        .style('opacity', 0)
      this.stateBoundariesMerge      
        .transition()
        .duration(0)
        .style('opacity', 0)
      this.countiesMergeTransition = this.countiesMergeTransition      
        .transition()
        .delay(Math.random() * 1000)
        .duration(50)
        .style('opacity', 1)
    }

    if (state.stepIndex > 3) {
      this.nationBoundariesMerge
        .transition()
        .duration(500)
        .style('opacity', 0)
      this.countiesMergeTransition = this.countiesMergeTransition
        .transition()
        .style('opacity', 0)
      this.stateBoundariesMergeTransition = this.stateBoundariesMergeTransition
        .transition()
        .delay(state.previousStepIndex === 3 ? 3000 : 0)
        .duration(500)
        .style('opacity', 0)
      this.statesHexMergeTransition
        .transition()
        .delay((d, i)=> 1000 + (i * 50))
        .duration(700)
        .style('opacity', 1)
    } else {
      this.statesHexMergeTransition
        .transition()
        .duration(0)
        .style('opacity', 0)
    }

    if (state.stepIndex === 4) {
      this.annotations
        .transition()
        .delay(3000)
        .style('opacity', 1)
    } else {
      this.annotations
        .style('opacity', 0)
    }

    this.drawHexGrid()
    if (state.stepIndex === 5 && state.previousStepIndex < 5) {
      this.statesHexMergeTransition = this.statesHexMergeTransition
        .transition()
        .delay((d, i) => (d.properties.rank**0.9)*100)
        .duration(4000)
        .attrTween('d', d => flubber.interpolate(path(d), d.properties.hexPolygon, {maxSegmentLength: 2}))
      this.hexGrid
        .transition()
        .delay(6500)
        .style('opacity', 1);
    } else if (state.stepIndex === 4 && state.previousStepIndex > 4) {
      this.statesHexMergeTransition = this.statesHexMergeTransition
        .transition()
        .delay((d, i) => (d.properties.rank**0.9)*100)
        .duration(4000)
        .attrTween('d', d => flubber.interpolate(d.properties.hexPolygon, path(d), {maxSegmentLength: 2}))
      this.hexGrid.style('opacity', 0);
    } 
    
    if (state.stepIndex < 4) {
      this.statesHexMergeTransition
        .attr('d', d => path(d))
    } else if (state.stepIndex > 5) {
      this.statesHexMergeTransition
        .attr('d', d => d.properties.hexPolygon)
    }

    if (state.stepIndex !== 4) {
      this.hexGrid
        .style('opacity', 0)
    }
  }

  drawHexGrid() {
    const gridBase = statesHex[statesHex.length - 1].properties.hexNormalized
    const minMaxCellX = d3.extent(gridBase.map(c => c[0]))
    const minMaxCellY = d3.extent(gridBase.map(c => c[1]))
    const cellWidth = (minMaxCellX[1] * (this.props.width*0.75)) - (minMaxCellX[0] * (this.props.width*0.75))
    const cellHeight = (minMaxCellY[1] * (this.props.width*0.75)) - (minMaxCellY[0] * (this.props.width*0.75))
    const grid = new Array(1750).fill(1).map((_,i) => i)
    this.hexGrid
      .attr("transform",`translate(${-cellWidth*7}, ${-cellHeight*21})`);
    
    this.hexGrid.selectAll(".gridCell")
      .data(grid)
      .enter().append("path")
        .attr("class", "gridCell")
        .attr("fill", "none")
        .attr("stroke", i => i === 1035 ? "grey" : "white")
        .attr("stroke-width", i => i === 1035 ? this.props.width * 0.004 : 0.1)
        .attr("d", this.line(gridBase))
        .attr("transform", i => `translate(${cellWidth*(i%50) + ((Math.floor(i/50) % 2) * cellWidth /2)}, ${3*cellHeight/4*Math.floor(i/50)})`);

    this.hexGrid.selectAll('text')
      .data([1])
      .enter().append('text')
        .attr('class', 'hexLegendText')
        .attr('text-anchor', 'start')
        .attr('font-size', this.props.width * 0.013)
        .attr('font-weight', 700)
        .style('fill', 'grey')
        .attr('x', this.props.width * 0.93 )
        .attr('y', this.props.width * 0.832 )
        .text('1 Elector')

    this.drawAnnotations();
  }

  drawAnnotations() {
    const type = d3Annotation.annotationCalloutCurve
    const annotations = [
        {
          id: 1,
          note: {
            title: "Montana",
            label: "380,832 km²\n3 Electors",
            wrap: 100,
            wrapSplitter: /\n/,
          },
          x: (315.32470703125/959) * this.props.width,
          y: (112.7001953125/959) * this.props.width,
          dx: (97.93017578125/959) * this.props.width,
          dy: (-57.3818359375/959) * this.props.width,
          connector: {
            end: "arrow",
            points: [[(32.465087890625/959) * this.props.width, (-37.69091796875/959) * this.props.width]]
          }
        }, {
          id: 2,
          note: {
            title: "New Jersey",
            label: "22,608 km²\n14 Electors",
            wrap: 100,
            wrapSplitter: /\n/,
          },
          x: (869.14794921875/959) * this.props.width,
          y: (234.3369140625/959) * this.props.width,
          dx: (-87.42138671875/959) * this.props.width,
          dy: (-126.494140625/959) * this.props.width,
          connector: {
            end: "arrow",
            points: [[(-22.710693359375/959) * this.props.width, (-66.7470703125/959) * this.props.width]]
          }    
        }
    ]

    const makeAnnotations = d3Annotation.annotation()
      //.editMode(true)
      .notePadding(0)
      .type(type)
      .annotations(annotations)

    //window.makeAnnotations = makeAnnotations
    
    this.annotations
      .style('font-size', this.props.width * 0.015)
      .style('fill', "black")
      .call(makeAnnotations)
  }
}
