import React, { Component } from 'react'
import { Row } from 'antd'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';

export default class Share extends Component {
  state = {};

  render() {
    const { vertical } = this.props;
    const url = 'http://try-to-impeach-this.jetpack.ai'
    return (
      <div>
        { vertical && 
          <div>
            <div style={{ marginBottom: 8}}>Share:</div>
            <div>
              <TwitterShareButton url={url} title="Try to impeach this? Challenge accepted!" via="karim_douieb" hashtags={["HowChartsLie", "USElection", "Cartogram", "D3js", "DataViz"]}>
                <TwitterIcon size={48} round={true}/>
              </TwitterShareButton>
            </div>  
            <div>
              <LinkedinShareButton url={url} title="Try to impeach this? Challenge accepted!" description="A visual exploration of why traditional electoral maps are misleading.">
                <LinkedinIcon size={48} round={true}/>
              </LinkedinShareButton>
            </div>
            <div>
              <FacebookShareButton url={url} quote="Try to impeach this? Challenge accepted!"> 
                <FacebookIcon size={48} round={true} />
              </FacebookShareButton>
            </div>
          </div>
        }
        { !vertical &&
          <Row type="flex">
            <TwitterShareButton url={url} title="Try to impeach this? Challenge accepted!" via="karim_douieb" hashtags={["USElection", "Cartogram", "HowChartsLie", "D3js", "DataViz"]}>
              <TwitterIcon size={48} round={true}/>
            </TwitterShareButton>
            <LinkedinShareButton url={url} title="Try to impeach this? Challenge accepted!" description="A visual exploration of why traditional electoral maps are misleading.">
              <LinkedinIcon size={48} round={true}/>
            </LinkedinShareButton>
            <FacebookShareButton url={url} quote="Try to impeach this? Challenge accepted!"> 
              <FacebookIcon size={48} round={true} />
            </FacebookShareButton>
          </Row>
        }
      </div>
    );
  }
}