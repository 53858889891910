import React, { Component } from 'react'
import { Row, Col, Carousel, Button } from 'antd'
import { Tada } from 'react-motions'

import About from '../About/About';
import USElectionChart from '../Charts/USElectionChart/USElectionChartComponent'
import Share from '../Share/Share'

const steps = ['intro', 'bubble', 'nation', '', '', '', '']

class USElection extends Component {
  constructor() {
    super()
    this.state = {
      previousStepIndex: -1,
      step: steps[0],
      stepIndex: 0,
      width: 0, 
      height: 0,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  
  onChange = (stepIndex) => {
    const { stepIndex: previousStepIndex} = this.state;
    this.setState({ previousStepIndex, step: steps[stepIndex], stepIndex })
  }

  prev = () => {
    this.refs.carousel.prev()
  }

  next = () => {
    this.refs.carousel.next()
  }

  restart = () => {
    this.refs.carousel.goTo(0, true)
  }

  render = () => {
    const { step, previousStepIndex, stepIndex, width } = this.state;
    return (
      <div>
        <Carousel afterChange={this.onChange} style={{ width: width < 900 ? '90%' : '60%' , margin: 'auto' }} ref="carousel">
          <div>
            <div style={{ marginTop: 30 }}>
              <div className='title'>Try to impeach this?</div>
              <div className='title'>Challenge accepted!</div>
            </div>
          </div>
          <div>
            <div className='title'>"Acres don't vote, people do"</div>
            <p>Traditional electoral maps are misleading. They advantage geographical accuracy over electoral importance. The region areas are by no means representative of the true result of an election.</p>
            <p style={{ marginBottom: 16 }}>Representing each county by a dot sized proportionally to the amount of its associated voters, offers a much different reading of the situation. This arguably provides a better understanding of how the country voted.</p>
          </div>
          <div>
            <div className='title'>Popular vote</div>
            <p>Although the previous map provides a more accurate view of the 2016 U.S. election it is still widely inaccurate as the electoral result of a county is not binary (all republicans or all democrats). Instead we can show the proportion of parties by splitting the dots according to the result. This way the ratio between the number of red & blue pixels in the picture corresponds to the popular vote ratio. Namely 65,853,514 people voted <strong style={{ color: "#0e0eb9" }}>democrat</strong> (48.2%) vs 62,984,828 who voted <strong style={{ color: "#e90103"}}>republican</strong> (46.1%).</p>
          </div>
          <div>
            <div className='title'>Electoral College</div>
            <p>In the U.S. presidential election system, instead of the nationwide popular vote determining the outcome of the election, the president of the United States is determined by votes cast by electors of the Electoral College. 
             The Electoral College consists of 538 electors. States have a certain amount of electors and most states have a “winner-take-all” system that awards all electors to the Presidential candidate who wins the State's popular vote. The candidate who wins the majority of electors nationally wins the presidency.</p>
          </div>
          <div>
            <div className='title'>Traditional map</div>
            <p>Traditional maps in the context of the electoral college are still very misleading. For example the geographic surface area of the state of Montana is almost 17 times bigger than the state of New Jersey. But New Jersey has 14 votes as opposed to 3 for Montana, almost 5 times as many.</p>
          </div>
          <div>
            <div className='title'>Cartogram</div>
            <p>Numerous alternatives to the traditional maps can be found all over the <a target="_blank" rel="noopener noreferrer" href="http://carto.maps.arcgis.com/apps/MinimalGallery/index.html?appid=b3d1fe0e8814480993ff5ad8d0c62c32">internet</a>. An interesting one proposed by <a target="_blank" rel="noopener noreferrer" href="https://projects.fivethirtyeight.com/2016-election-forecast/#electoral-map">FiveThirtyEight</a> is an hexamap where each state has a surface area corresponding to its number of electors (one hexagon for one elector). The advantage of this representation is that it shows what is really important in the context of the U.S. election, i.e. the number of electors, while preserving some familiar geographical references such as the position of the states and their boundaries.</p>
          </div>
          <div>
            <div style={{ marginTop: 60 }} className='title'>About</div>
          </div>
        </Carousel>
        {
          width < 900 && 
          <div>
            <Row type="flex" justify="space-between" style={{ height: 48 }}>
              <div>
                { stepIndex > 0 && <Button style={{ marginLeft: 32 }} shape="circle" icon="left" size="large" onClick={this.prev} /> }
              </div>
              <div style={{ marginRight: 32, textAlign: 'center' }}>
                { stepIndex < 6 && <Button shape="circle" icon="right" size="large" onClick={this.next} /> }
                { stepIndex === 0 && <Tada style={{ marginRight: 32 }} duration={2} infinite>Let's Start!</Tada> }
                { stepIndex === 6 && <Button  shape="circle" icon="reload" size="large" onClick={this.restart} />}
                { stepIndex === 6 && <div>Restart</div> }
              </div>
            </Row>
            {
              stepIndex === 6 &&
              <About />
            }
            <div style={{ visibility: stepIndex < 6 ? 'visible' : 'hidden', textAlign: 'center' }}>
              <div style={{ width: '100%' }}>
                <USElectionChart step={step} previousStepIndex={previousStepIndex} stepIndex={stepIndex}/>
              </div>
              <div style={{ alignText: 'center', fontSize: 12, color: 'lightgrey' }}>Result of the 2016 U.S. presidential election.</div>
              <Row type="flex" style={{ marginTop: 16 }} justify="center">
                <Share/>
              </Row>
            </div>
          </div>
        }
        {
          width >= 900 && 
          <Row type="flex" justify="start">
            <Col span={4} style={{ textAlign: 'center', marginTop: '25vh' }}>
              { stepIndex > 0 && <Button shape="circle" icon="left" size="large" onClick={this.prev} /> }
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 16 }}>
              {
                stepIndex === 6 &&
                <About />
              }
              <div style={{ visibility: stepIndex < 6 ? 'visible' : 'hidden', textAlign: 'center' }}>
                <div style={{ width: '95%' }}>
                  <USElectionChart step={step} previousStepIndex={previousStepIndex} stepIndex={stepIndex}/>
                </div>
                <div style={{ alignText: 'center', fontSize: 12, color: 'lightgrey' }}>Result of the 2016 U.S. presidential election.</div>
              </div>
            </Col>
            <Col span={4} style={{ textAlign: 'center', marginTop: '25vh'}} >
              { stepIndex < 6 && <Button shape="circle" icon="right" size="large" onClick={this.next} /> }
              { stepIndex === 0 && <Tada duration={2} infinite>Let's Start!</Tada> }
              { stepIndex === 6 && <Button  shape="circle" icon="reload" size="large" onClick={this.restart} />}
              { stepIndex === 6 && <div>Restart</div> }
              <div style={{ marginTop: 100 }}>
                <Share vertical/>
              </div>
            </Col>
          </Row>
        }
      </div>
    );
  }
}

export default USElection
