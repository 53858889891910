import React, { Component } from 'react'
import { Row, Col } from 'antd'
import { TwitterTweetEmbed } from 'react-twitter-embed';

import Share from "../Share/Share";

class About extends Component {
  constructor() {
    super()
    this.state = {
    }
  }
  
  render = () => {
    return (
      <Row style={{ marginTop: 30 }}>
        <Col xs={{ span: 22, offset: 1}} lg={{ span: 12 }}>
          <p>My name is <a rel="noopener noreferrer" href="https://www.linkedin.com/in/karim-douieb/" target="_blank">Karim Douieb</a>, I am fond of data visualisation. I co-founded <a rel="noopener noreferrer" href="https://www.jetpack.ai" target="_blank"> <img alt="map" style={{marginLeft: 8, marginRight: 8, width: 120}} src={require(`../../assets/images/Jetpack-small.png`)}/></a> a full stack data science company based in Brussels.</p>
          <p>The visuals used in this essay have been mentioned in a number of newspapers and blog posts:</p>
          <ul>
            <li>
            FlowingData: <a rel="noopener noreferrer" href="https://flowingdata.com/2019/10/08/fixing-the-impeach-this-map-with-a-transition-to-a-cartogram/" target="_blank">Fixing the ‘impeach this’ map with a transition to a cartogram</a>.
            </li>
            <li>
              AnyChart: <a rel="noopener noreferrer" href="https://www.anychart.com/blog/2019/10/11/exploring-data-drought-impeachment-opioids-tax/" target="_blank">DataViz Weekly</a>.
            </li>
            <li>
              Core77: <a rel="noopener noreferrer" href="https://www.core77.com/posts/90771" target="_blank">A Great Example of Better Data Visualization: This Voting Map GIF</a>.
            </li>
            <li>
              Puntofisso's newsletter: <a rel="noopener noreferrer" href="https://mailchi.mp/a4bbc86cc07b/preview-222-in-other-news-3750685?e=b74df1ac1a" target="_blank">Land doesn't vote</a>.
            </li>
            <li>
              DemCast: <a rel="noopener noreferrer" href="https://demcastusa.com/2019/11/11/land-doesnt-vote-people-do-this-electoral-map-tells-the-real-story/" target="_blank">Land Doesn’t Vote, People Do: This Electoral Map Tells the Real Story</a>.
            </li>
            <li>
             Brilliant Maps: <a rel="noopener noreferrer" href="https://brilliantmaps.com/2016-election-map-population/" target="_blank">2016 US Presidential Election Maps By Population Vs Land Area</a>.
            </li>
            <li>
              Reddit posts [
                <a rel="noopener noreferrer" href="https://www.reddit.com/r/gifs/comments/dg315w/land_doesnt_vote_people_do/" target="_blank">1</a>, 
                <a rel="noopener noreferrer" href="https://www.reddit.com/r/Fuckthealtright/comments/dg35cx/land_doesnt_vote_people_do/" target="_blank">2</a>, 
                <a rel="noopener noreferrer" href="https://www.reddit.com/r/DataArt/comments/dgg2b7/land_doesnt_vote_people_do/" target="_blank">3</a>
              ]
            </li>
            <li>
              FlowingData: <a rel="noopener noreferrer" href="https://flowingdata.com/2019/12/19/best-data-visualization-projects-of-2019/" target="_blank">Best Data Visualization Projects of 2019</a>.
            </li>
            <li>
              VisualisingData: <a rel="noopener noreferrer" href="https://www.visualisingdata.com/2019/12/best-of-the-visualisation-web-october-2019/" target="_blank">Best of the visualisation web - October 2019</a>.
            </li> 
          </ul>
          <p>Adaptations of the map transition idea to other contexts have been realized by a few media and other individuals:</p>
          <ul>
            <li>
              NZZ Visuals <a rel="noopener noreferrer" href="https://twitter.com/nzzvisuals/status/1186225049960943617" target="_blank">2019 Swiss election results</a>.
            </li>
            <li>
              The Zeit <a rel="noopener noreferrer" href="https://www.zeit.de/politik/deutschland/2019-10/landtagswahl-thueringen-wahlergebnisse-gemeindeebene-karte" target="_blank">Rot-blau marmoriertes Thüringen</a>.
            </li>
            <li>
              Adrián Blanco <a rel="noopener noreferrer" href="https://twitter.com/AdrianBlancoR/status/1193755873191092225" target="_blank">Elecciones Generales 10N</a>.
            </li>
            <li>
              Jens von Bergmann <a rel="noopener noreferrer" href="https://twitter.com/vb_jens/status/1186753407756857344" target="_blank">Canada's election</a>.
            </li>
            <li>
              Arnold Platon <a rel="noopener noreferrer" href="https://twitter.com/Arnold_Platon/status/1186383905718460419" target="_blank">EU election</a>.
            </li>
          </ul>
          <p>
            References and inspirations:
          </p>
          <ul>
            <li>
              Alberto Cairo's book on <a rel="noopener noreferrer" href="https://www.amazon.com/How-Charts-Lie-Getting-Information-dp-1324001569/dp/1324001569/ref=mt_hardcover?_encoding=UTF8&me=&qid=" target="_blank">How charts lie</a>.
            </li>
            <li>
              Vox's video on <a rel="noopener noreferrer" href="https://www.youtube.com/watch?v=hlQE4IGFc5A" target="_blank">The bad map we see every presidential election</a>.
            </li>
            <li>
              FiveThirtyEight's 2016 Election Forecast: <a rel="noopener noreferrer" href="https://projects.fivethirtyeight.com/2016-election-forecast/" target="_blank">Who will win the presidency?</a>
            </li>
          </ul>
          <p>The source code of the animated map is openly available on this <a rel="noopener noreferrer" href="https://observablehq.com/@karimdouieb/try-to-impeach-this-challenge-accepted">Observable notebook</a>.</p>
          <div style={{ paddingTop: 32, paddingBottom: 64 }}>
            <Share />
          </div>
          {/*<p style={{ fontSize: 12 }}>
            <strong>Update</strong>: Since the publication of this post a few adjustments have been made: a. It was previously mentioned that <em>"6 out of 10 Brussels residents where born abroad"</em> where it should have been <em>"born non-Belgian"</em>; b. Previously residents born without the Belgian nationality were referred as immigrants while they have to be considered as foreigners instead (since children of immigrants are not necessarily immigrants themselves).
            <br/> Also it is worth mentioning that this essay has no political intention and only aims at making the data publicly available about Brussels population more understandable through visualization.  
            </p>*/}
        </Col>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 10, offset: 1 }}>
          <p>This work is a consolidation of a series of tweets that I originally posted in response to Lara Trump: </p>
          <div>
            <TwitterTweetEmbed style={{ margin: 'auto' }} tweetId={'1181695687005745153'} />
          </div>
        </Col>
      </Row>
    );
  }
}

export default About
