import React from 'react';
import './App.css';

import USElection from './Components/USElection/USElectionMain'

function App() {
  return (
    <div>
      <USElection />
    </div>
  );
}

export default App;
