import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import USElectionChart from './USElectionChart';

function getChartState(props) {
  return {
    step: props.step,
    previousStepIndex: props.previousStepIndex,
    stepIndex: props.stepIndex,
  };
}

class USElectionChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null,
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
  }

  componentDidUpdate(prevProps) {
    const { chart } = this.state;
    const { stepIndex } = this.props;
    const currentState = getChartState(this.props);
    if (stepIndex !== prevProps.stepIndex && chart) {
      chart.update(currentState);
    }
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    window.removeEventListener('resize', this.createChart);
  }

  createChart() {
    const el = ReactDOM.findDOMNode(this.refs.chart);
    const { chart } = this.state
    if (chart) {
      chart.destroy()
    }

    const margin = {
      top: 5,
      right: 40,
      bottom: 5,
      left: 25,
    };

    const elWidth = el.offsetWidth;
    const elHeight = elWidth * 5/8;
    const props = {
      margin,
      width: (elWidth - margin.left) - margin.right,
      height: (elHeight - margin.top) - margin.bottom,
    };

    // Initialise the chart, then render it without transitions.
    this.setState({
      chart: new USElectionChart(el, props),
    }, function callback() {
      this.state.chart.create();
      this.state.chart.update(getChartState(this.props));
      this.state.chart.preventTransitions();
    });
  }

  render() {
    return (
      <div
        ref='chart'
      />
    );
  }
}

export default USElectionChartComponent
